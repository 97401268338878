<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker v-model="search.date_range" type="daterange" size="small"
                :picker-options="date_picker_options" range-separator="To" start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select v-model="statusFilter" size="small">
                <el-option v-for="(item, i) in statusFilterOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-select v-model="channelType" size="small">
                <el-option v-for="(item, i) in channelTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-select v-model="directionFilter" size="small">
                <el-option v-for="(item, i) in directionFilterOptions" :key="i" :value="item.value"
                  :label="item.text" />
              </el-select> &nbsp;
              <el-button :loading="loading.table" @click="page = 1; getCallList();" size="small" class="btn-default"
                icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary"
                icon="el-icon-download">Download</el-button>
            </div>
            <hr />
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Channel</th>
                    <th>Customer Phone</th>
                    <th>Call Date & Time</th>
                    <th>Answered Time</th>
                    <th>Answered Duration</th>
                    <th>End Time</th>
                    <th>Call Duration</th>
                    <th>Call Direction</th>
                    <th>Agent Name</th>
                    <th>Agent Email</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="(item, i) in reportCallList" :key="i">
                    <td>
                      <el-tag size="mini" :type="item.status_color">
                        {{ item.status_str }}
                      </el-tag>
                    </td>
                    <td>
                      {{ item.channel_type ? ucwords(item.channel_type) : '' }}
                    </td>
                    <td>
                      {{ item.identifier }}
                    </td>
                    <td>
                      {{ item.calling_at ? formatDate(item.calling_at) : formatDate(item.created_at) }}
                    </td>
                    <td>
                      {{ item.answered_at ? formatDate(item.answered_at) : '' }}
                    </td>
                    <td>
                      {{ humanizeDuration(item.ring_duration * 1000) }}
                    </td>
                    <td>
                      {{ item.terminated_at ? formatDate(item.terminated_at) : '' }}
                    </td>
                    <td>
                      {{ item.duration ? humanizeDuration(item.duration * 1000) : '-' }}
                    </td>
                    <td>
                      {{ item.type_str }}
                    </td>
                    <td>
                      {{ item.agent?.name ? item.agent.name : '-' }}
                    </td>
                    <td>
                      {{ item.agent?.name ? item.agent.email : '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="pagination.totalRow == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="pagination.totalRow > pagination.pageLimit" v-model="currentPage" :total-rows="pagination.totalRow"
              :per-page="pagination.pageLimit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isEqual, upperFirst } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'CallReports',
  metaInfo: {
    title: 'Call Reports',
  },
  data() {
    return {
      search: {
        date_range: [
          this.$route.query?.start_date || new Date(),
          this.$route.query?.end_date || new Date(),
        ],
      },
      statusFilterOptions: [
        {
          value: '',
          text: 'All Status',
        },
        {
          value: 'calling', // ringing, answered
          text: 'Progress',
        },
        {
          value: 'answered', // completed
          text: 'Completed',
        },
        {
          value: 'no_answer', // others
          text: 'Failed',
        },
      ],
      channelTypeOptions: [
        {
          value: '',
          text: 'All Channel',
        },
        {
          value: 'whatsapp',
          text: 'WhatsApp',
        },
        {
          value: 'phone_call',
          text: 'VoIP Call',
        },
      ],
      directionFilterOptions: [
        {
          value: '',
          text: 'All Type',
        },
        {
          value: 'incoming',
          text: 'Incoming',
        },
        {
          value: 'outgoing',
          text: 'Outgoing',
        },
      ],
      loaderStack: 0,
      loader: null,
      statusFilter: this.$route.query.status_filter || '',
      channelType: this.$route.query.channel_type || '',
      directionFilter: this.$route.query.direction_filter || '',
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      loading: {
        table: false,
      },
      pagination: {
        page: this.$route.query.page || 1,
        totalRow: 0,
        pageLimit: 50,
      },
      reportCallList: {
        count: 0,
        rows: [],
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
        this.getCallList();
      },
    },
  },
  async mounted() {
    this.showLoader();
    await this.getCallList();
    this.hideLoader();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    formatDate(string, format = 'DD MMM YYYY, HH:mm:ss') {
      return string ? moment(string).format(format) : '-';
    },
    space(str) {
      return str.replace(/_/gi, ' ');
    },
    ucwords(str) {
      /* eslint-disable no-plusplus */
      const words = this.space(str).split(' ');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(' ');
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // this.loading.table = true;
            if (isEmpty(this.search.date_range)) {
              this.search.date_range = [new Date(), new Date()];
            }
            const [start_date, end_date] = this.search.date_range;
            const response = await reportApi.downloadCallList({
              company_id: this.company_id,
              workspace_id: this.activeWorkspace._id,
              start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
              end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
              call_type: this.callType,
              channel_type: this.callChannel,
              status_filter: this.status_filter,
            }).catch(() => { });
            // this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    async getCallList() {
      this.loading.table = true;
      const options = {
        page: this.pagination.page,
        status_filter: this.statusFilter,
        direction_filter: this.directionFilter,
        channel_type: this.channelType,
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
      };
      const response = await reportApi.getCallList({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        ...options,
      }).catch(() => { });
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.loading.table = false;
      await popupErrorMessages(response);

      this.pagination.totalRow = response.data.count;
      this.reportCallList = response.data.rows.map((call) => {
        call.status_str = 'Failed';
        call.status_color = 'danger';

        if (call.duration && ['completed', 'answered'].includes(call.status)) {
          call.status_str = 'Success';
          call.status_color = 'success';
        } else if (['ringing'].includes(call.status)) {
          call.status_str = 'Progress';
          call.status_color = 'info';
        } else {
          call.status_str = this.ucwords(call.status);
          call.status_color = 'danger';
        }

        if (call.rejected_by_user) {
          call.agent = call.rejected_by_user;
        }
        if (call.accepted_by_user) {
          call.agent = call.accepted_by_user;
        }

        if (call.rejected_by_user) {
          call.agent = call.rejected_by_user;
        }
        if (call.accepted_by_user) {
          call.agent = call.accepted_by_user;
        }
        if (call.direction) {
          call.type_str = upperFirst(call.direction);
        }

        call.ring_duration = call.answered_at ? moment(call.answered_at || call.rejected_at || call.terminated_at).diff(moment(call.ringing_at || call.created_at), 'second') : '';

        return call;
      });

      if (response.data.rows) {
        this.reportCallList.rows = response.data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (v.scheduled_at) {
            v.scheduled_at_str = moment(v.scheduled_at).format('DD MMM YYYY, HH:mm');
          }
          v.progress = (v.waiting_count / v.total_recipients) * 100;
          v.channel_icon = `mdi mdi-${v.channel_name}`;
          if (v.channel_name === 'whatsapp') {
            v.channel_name_str = 'WhatsApp';
            v.channel_color = 'text-success';
          }
          v.progress_str = `${v.complete_count} of ${v.total_recipients}`;
          v.total_amount_str = `Rp. ${v.total_amount ? v.total_amount.toLocaleString() : 0}`;
          return v;
        });
      }
      this.loading.table = false;
    },
  },
};
</script>
